.email-confirmation-page .fa {
  color: var(--cj-green);
  font-size: x-large;
}

#resend-btn {
  min-width: 200px;
}

.email-confirmation-page .vsx-mdi-icon {
  color: var(--color-icon-success);
}

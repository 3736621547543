.branded-signup-layout {
  height: 100vh;
}

.branded-signup-layout .vsx-cj-logo {
  height: 32px;
  width: 32px;
  box-sizing: border-box;
}

.branded-signup-layout .title {
  font-weight: var(--font-font-weight-medium);
}

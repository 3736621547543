.horizontal-line-default,
.horizontal-line-solid,
.horizontal-line-dashed {
  border: 0;
  border-top: 1px solid #e1e1e1;
  box-sizing: initial;
  height: 0;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  overflow: visible;
}

.horizontal-line-default {
  width: 100%;
  border-style: solid;
  margin: 0;
}

.horizontal-line-solid {
  width: 100%;
  border-style: solid;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.horizontal-line-dashed {
  width: 100%;
  border-style: dashed;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

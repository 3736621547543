.tag {
  background-color: #826be1;
  font-weight: 500;
  font-size: 12px;
  border-radius: 12px;
  display: inline-block;
  color: #ffffff;
  padding: 2px 12px;
  text-align: center;
}

.actions-button {
  cursor: pointer;
}

.actions-button .vsx-icon-btn {
  padding: 4px 12px;
}

.actions-button .vsx-dropdown {
  border-radius: 3px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  padding: 8px 0;
}

.actions-button .vsx-dropdown .actions-button-action {
  padding: 8px 16px;
}

.actions-button .vsx-dropdown .actions-button-action:hover {
  background-color: #f6f6f6;
}

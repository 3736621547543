.browser-choice-container {
  display: flex;
  align-content: baseline;
}

.browser-choice-container .vsx-choice-input label {
  width: 7.5rem;
  padding-right: 0;
}

.browser-choice-textbox {
  flex: 1;
}

.browser-input-container {
  flex: 1 1;
  margin-bottom: 5px;
}

.vsx-cjform-social-input {
  flex-basis: 50%;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

#influencer-wizard .vsx-top-nav-layout-title {
  color: white;
  line-height: 0.25;
  padding-bottom: 0.25em;
}

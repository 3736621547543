.vsx-choice-input label {
  font-weight: 500;
}

.promotional-model-set-primary-helper {
  margin: 0 4px;
}

.promotional-model .tag {
  margin: 8px;
}

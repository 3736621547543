.alert {
  z-index: 99999;
  width: 780px;
  background-color: white;
  left: 0;
  right: 0;
  margin: auto;
  top: 85px;
  position: fixed;
  padding: 16px;
  -webkit-box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
  line-height: 1.6;
}

.alert-icon {
  display: inline;
  float: left;
}

.alert-close-icon {
  position: absolute;
  display: inline;
  top: 15px;
  right: 16px;
  opacity: 0.6;
}

.alert-close-icon:hover {
  opacity: 1;
}

.alert-close-button {
  position: absolute;
  display: inline-block;
  right: 16px;
  margin-top: -4px;
}

.alert .content {
  width: 85%;
  display: inline-block;
  padding: 0 14px;
}

.alert ul {
  padding-left: 3.2rem;
  margin-top: 0;
  margin-bottom: 1.6rem;
}

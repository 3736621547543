.mobile-platform-choice-container {
  display: flex;
  align-content: baseline;
}

.mobile-platform-choice-container .vsx-choice-input label {
  width: 7.5rem;
  padding-right: 0;
}

.mobile-platform-choice-textbox {
  flex: 1;
}

.platform-input-container {
  flex: 1 1;
  margin-bottom: 5px;
}

.view-promotional-properties {
  line-height: 1.6;
}

.view-promotional-properties a {
  color: #2893bc;
  text-decoration: none;
  background-color: transparent;
  transition: 0.2s all ease-in-out;
}

.view-promotional-properties a:hover {
  color: #2893bc;
  text-decoration: underline;
}

.view-promotional-properties a:focus {
  outline: thin dotted;
  outline: 4px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.centered-td {
  text-align: center;
}

.vsx-table-container {
  background-color: #ffffff;
  padding: 0px;
  margin: 16px 0px;
  border: 0px;
  min-width: min-content;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 2px 3px -1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08),
    0 2px 3px -1px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 2px 3px -1px rgba(0, 0, 0, 0.08);
}

.vsx-table-container *,
.vsx-table-container :before,
.vsx-table-container :after {
  box-sizing: border-box;
}

.vsx-table-title {
  margin: 0px;
}

.table-toolbar {
  margin-left: auto;
}

.table-toolbar .status-select {
  width: 160px;
  margin: 0px;
}

.table-toolbar .status-select .vsx-react-select-control {
  border-radius: 3px;
  cursor: pointer;
}

.vsx-table .vsx-thead .vsx-cell {
  border-bottom: 0px;
}

.vsx-thead .vsx-cell:last-child {
  width: 70px;
}

.vsx-table .vsx-cell {
  padding: 8px;
  vertical-align: middle;
}

.vsx-cell:first-child {
  padding-left: 12px;
}

.vsx-cell:last-child {
  padding-right: 12px;
}

.create-property-button {
  margin-left: 0px;
}

.vsx-pagination {
  height: auto;
  padding: 12px;
}

.view-promotional-properties .tag {
  margin-left: 32px;
}

.no-margin .vs-dialog-layout-content {
  padding: 80px 16px 80px 16px;
}

.no-margin .vsx-table-container {
  margin: 0px;
}

.loading-container {
  text-align: center;
}

.api-info {
  align-self: flex-end;
  margin-left: 16px;
}

.api-info.render-center {
  align-self: center;
}

.view-promotional-properties .vsx-react-select-value-container {
  line-height: 1em;
  font-size: 13px;
}

.view-promotional-properties
  .vsx-react-select-input.vsx-react-select-input
  input {
  height: 16px;
}

.vsx-styled-form.vsx-expanding-input-button input[type="text"] {
  margin-bottom: 0;
}

/* move vsx alerts down in old nav so they don't overlap top bar */
/* TODO: remove after 2022 nav launches */
.vsx-topnav-content .vsx-alert-d {
  top: 85px;
}

.promotional-property-form-wrapper {
  width: 770px;
  margin: 0 auto;
  line-height: 1.6;
}

.promotional-property-form-wrapper .cj-panel.panel {
  margin: 5em auto;
}

.promotional-property-form-wrapper
  .vsx-layout-section
  .vsx-layout-section-children {
  margin-bottom: 16px;
}

.promotional-property-form-wrapper .field-array .vsx-field {
  margin: 0;
}

.promotional-property-form-wrapper .vsx-choice-input + .vsx-field-help {
  margin-left: 21px;
}

.promotional-property-form-wrapper .panel-heading {
  font-size: 1.6rem;
  padding: 0px;
}

.promotional-property-form-wrapper .primary-field > .form-label {
  display: none;
}

.promotional-property-form-wrapper .vsx-layout-section {
  --separator: none;
  padding: 4px;
}

/*
  CSS adjustments for legacy VSX forms 
  so that spacing and styling matches what was there before
*/

.vsx-styled-form {
  margin: 0 auto;
}

.vsx-styled-form select,
.vsx-styled-form textarea,
.vsx-styled-form input[type="file"],
.vsx-styled-form input[type="text"],
.vsx-styled-form input[type="password"],
.vsx-styled-form input[type="date"],
.vsx-styled-form input[type="datetime"],
.vsx-styled-form input[type="datetime-local"],
.vsx-styled-form input[type="month"],
.vsx-styled-form input[type="time"],
.vsx-styled-form input[type="week"],
.vsx-styled-form input[type="number"],
.vsx-styled-form input[type="email"],
.vsx-styled-form input[type="url"],
.vsx-styled-form input[type="search"],
.vsx-styled-form input[type="tel"],
.vsx-styled-form input[type="color"] {
  margin-bottom: 8px;
  font-size: 14px;
  font-family: "Roboto", -system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.vsx-styled-form .vsx-field-help,
.vsx-styled-form .vsx-validation-error {
  margin-top: -4px;
}

.vsx-styled-form .vsx-react-select-value-container {
  line-height: 1em;
  font-size: 13px;
}

.vsx-styled-form .vsx-react-select-input.vsx-react-select-input input {
  height: 16px;
}

.vsx-styled-form .vsx-react-select-control {
  margin-bottom: 8px;
}

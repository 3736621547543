/*
  Work around padding issue with CJForm where CSS
  layouts are not applying in the correct order on
  local, non-minified environments only.
  
  Minified builds in lab and prod work as expected.

  TODO: update create-react-app to see if it helps
*/
div.vsx-cjform-generic-input-wrapper {
  padding-left: 8px;
}

div.vsx-cjform-social-input {
  padding-left: 6px;
}

.property-type-row {
  display: flex;
}

.details-panel .vsx-choice-input label {
  font-weight: normal;
  padding-right: 32px;
  margin: 2px;
}

.details-panel .vsx-field {
  padding: 8px;
  flex: 1;
}
